import "./grade.css"
export default function GradeCanais(props){
    return(
        <>
        <div className={props.bg}>
        <img className="d-flex m-auto" src={props.pacote} width="60%"/>
        </div>
        
        </>
    )
}